/* html {
  touch-action: manipulation;
} */

@media (min-width: 768px) {
	.container {
	  max-width: 750px;
	}
  }
  
@media (min-width: 992px) {
  .container {
    max-width: 1250px;
  }
}
  
input:focus,
textarea:focus,
select:focus {
  outline: none;
}
  
.pull-right {
  float: right !important;
  height: max-content !important;
}

.pull-right img {
  height: auto !important;
}

.pull-right:after {
  padding-bottom: 15;
}

div.ReactTags__tags {
  position: relative;
  font-family: Segoe-Bold;
  color: #d32f2f;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 70px;
  border-radius: 2px;
  display: inline-block;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 2px;
  margin-left: 0px;
}

div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}
.maincontent{
  padding: 1px;
  position: relative;
  height: 100%;
  width: 100%;
}
.bg-black-transparent {
  background-color:rgba(0, 0, 0, 0.4);
}
.bg-91-112-131 {
  background-color:rgb(91 112 131 / 40%);
}
.navigationFullWidthNoTrans{
  height: 100%;
  position: fixed;

  width: 100%;
  z-index: 501;
  color: black;
}
.navigationsmallWidthNoTrans{
  height: 100%;
  position: fixed;
  /* background-color:rgba(0, 0, 0, 0.4); */
  width: 0px;
  z-index: 501;
  color: black;
}
.navigationFullWidth{
  height: 100%;
  position: fixed;

  width: 100%;
  z-index: 501;
  color: black;
  transform: translateX(0%);
  transition-property:'background-color';
  transition-timing-function:ease;
  transition-duration: 250ms;

}
.navigationsmallWidth{
  height: 100%;
  position: fixed;
  /* background-color:rgba(0, 0, 0, 0.4); */
  width: 0px;
  z-index: 501;
  color: black;
}
.bg-white{
  background-color: rgba(255,255,255,1.00);
}
.bg-black{
  background-color: #000;
}
.bg-21-32-43{
  background-color: #212121;
}
.text-white{
  color: #fff;
}
.text-black{
  color: #000;
}
.text-gray{
  color: rgb(113, 118, 123);
}
.width35{
  width: 35px;
}
.border-white{
  border: 1px solid white
}
.border-black{
  border: 1px solid black
}
.margin-top-2{
  margin-top: 2px;
}
.margin-bottom-2{
  margin-bottom: 2px;
}
.bg-475154 {
  background-color: rgb(47, 51, 54);
}
.height1{
  height: 1px;
}
.width89{
  width: 89%;
}
.navbar-brand{
  margin-right: 0 !important;
}
.icon-0-2-312 svg path{
  fill: #536471 !important;
  stroke: #536471 !important;
}

.navigationMainContentFullwidth {
  transform: translateX(0%);
  max-width: 70%;
  transition-timing-function: ease;
  min-width: 0px;
  transition-timing-function: ease;
  transition-duration: 250ms;
  transition-property: transform;
  height: 100vh;
  display: block;
  width: 100%;
 
}
.navigationMainContentNowidth {
  transform: translateX(0%);
  max-width: 70%;
  transition-timing-function: ease;
  transition-duration: 250ms;
  transition-property: transform;
  width: 0px;
  height: 100vh;
  display: none;
  background-color: rgba(255,255,255,1.00);
}
/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.CircularProgressbar-path {
  stroke: red;
}

.CircularProgressbar-trail {
  stroke: gray;
}

.MuiTabs-indicator {
  height: 4px !important;
  border-radius: 10px;
}

.MuiAlert-root {
  border-radius: 15px !important;
  font-weight: 600 !important;
  color: #ffffff !important;
}

.MuiAlert-standardSuccess {
  background: #28a745 !important;
}

.MuiAlert-standardError {
  background: #dc3545 !important;
}

.MuiAlert-outlinedSuccess {
  background: #28a745 !important;
}

.MuiAlert-outlinedError {
  background: #dc3545 !important;
}

.MuiAlert-icon {
  align-items: center;
  color: #ffffff !important;
  font-size: 30px !important;
}

.MuiBadge-colorSecondary {
  background-color: #e65768 !important;
  line-height: 2 !important;
}

@font-face {
  font-family: "Segoe-Bold";
  src: url("fonts/Segoe-Bold.woff");
}

a[rel="nofollow noopener"]::after {
  height: 5px;
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEASURBVHgB5ZPhkcIgEIV3iff/OjgbCMEKTivQDrQD7UBL0ArUSowVSMQCYgc2gOvimJkYiYr6z28mw/Agb5bHAvB1YHmyjeMBCPFf1qy1y9Zul26UUhHR8MbheFxLYxbFtFFeI8S2AOiXtQhx7caW1tootQei8dU/QrjBb1gQZxl6da0nRspfQBxCDQIC4GM3EbF7b8/Ths7sh2hFAE3+cq5y5tvXeMXMInY405wzPXCmf8EV8qZe1czpLlNbU+mZTMqFSRLyrik1cpXCA546siPRelrVXN/aKMq54rTQgm65Cgox52a/6tu3DH183NCbYd3FBBtyJikxEMLlrX8xJwaQX9VVF9daAAAAAElFTkSuQmCC");
  margin: 0px 1px 0px 1px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Animations */
@keyframes savedAsDraftAnimation {
  from {
    transform: scale(0);
  }
}

@keyframes counterAnimation {
  from {
    opacity: 0;
    color:white;
  }
}

@keyframes twitterLoader {
  from {
    transform: scale(0.8)
  }
  to {
    transform: scale(1)
  }
}

@keyframes closeBuzzButtonAnimation {
  from {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes buzzLoading {
  0% {
    transform: scale(0.8)
  }
  80% {
    transform: scale(1)
  }
  100% {
    transform: scale(0.8)
  }
}

@keyframes buzzLoadingContainer {
  from {
    opacity: 0;
    transform: scale(0);
    background: #E61C34;
  }
}

@keyframes pocketsLoader {
  0% {
    transform: scale(0.8)
  }
  80% {
    transform: scale(1)
  }
  100% {
    transform: scale(0.8)
  }
}

@keyframes skeleton-loading {
  0% {
    background: hsl(200, 20%, 85%); 
  }
  100% {
    background: hsl(200, 20%, 95%); 
  }
}

@keyframes avatar_loading {
  0% {
    opacity: 0.35;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

* {
  scroll-behavior: smooth;
}

/* *::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background: gray;
  width: 10px;
  border-radius: 30px;
} */

@keyframes eventContainerAnimation {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0 20px 80px rgba(209, 84, 22, 0.5);
  }
  100% {
    box-shadow: none;
  }
}

@keyframes showFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
    transform: scale(0.98)
  }
  100% {
    opacity: 1;
  }
}

@keyframes pocketNameInputAnimation {
  0% {
    box-shadow: inherit;
  }
  100% {
    box-shadow: 0 0 0 3px #FF625E,
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hyperlink {
  color: #3ea6ff !important;
  pointer-events: all;
  cursor: pointer;
}

.hyperlink:hover {
  text-decoration: underline;
}

.twitter-tweet {
  max-width: 100% !important;
}

.twitter-tweet > iframe {
  background-color: rgb(29, 155, 240);
  border: 1px solid rgb(29, 155, 240) !important;
}

.delete-buzz-button {
  color: #ffffff !important;
}

@media (prefers-color-scheme: dark) {
  /* Dark mode */
  ::-webkit-scrollbar-track {
      background: #333;
  }
  ::-webkit-scrollbar-thumb {
      background-color: #f50057; 
      border-radius: 20px;
      border: 3px solid #333;
  }
} 

@media (prefers-color-scheme: light) {
  /* Light mode */
  ::-webkit-scrollbar-track {
      background: #f2f2f2;
  }
  ::-webkit-scrollbar-thumb {
      background-color: #f50057; 
      border-radius: 20px;
      border: 3px solid #f2f2f2;
  }
}
